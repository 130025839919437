.aboutContainer{
    
     padding-top: 100px;
     padding-bottom: 100px;
     min-height: 730px;
     height: 90vh;
     display: flex;
     align-content: center;
 }

 @media  only screen and (max-width: 1200px) {
    .aboutContainer{
        max-width: 700px;
        height: 100%;
        
    }
}

@media  only screen and (max-width: 768px) {
    .aboutContainer{
        max-width: 500px;
        
    }
}

@media  only screen and (max-width: 450px) {
  
    .aboutContainer{
        max-width: 400px;
    }
}