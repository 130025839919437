@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baskervville&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.homeGrid{
    display: grid;
    width: 100%;
    min-width: 320px;
}

.home{
    
    width: 100%;
    background: url(/static/media/1.64580c0f.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.homeFooter{
    position: absolute;
    bottom:0;
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-around;
    align-content: flex-start;
    color:rgba(255, 255, 255, 0.925) !important;
    font-family: 'Libre Baskerville', serif;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 16px;
}



.homeFooterSub{
    font-style: italic;
    color: #ffffffa9;
}


.homeItem2{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    width: 100%;
    min-height: 800px;
    height: 100vh;
    
}

.homeItem2a{
    
    min-height: 275px;
    /* height: 60vh; */
    
}

.containerRow{
    width: 100%;
}
.homeItem2aText{
    color:grey ;
    font-family: 'Libre Baskerville', serif;
    letter-spacing: 1.6px;

    font-weight: 400;
    font-size: 16.5px;
    line-height: 2;
    padding-left: 30px;
    width:440px; 
    height:300px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Hello{
    font-size: 50px;
    font-weight: 400;
    line-height: 0.8;
}
.homeItem2aText a{
    color:grey ;
    font-style: italic;
    font-family: 'Libre Baskerville', serif;
}
.homeItem2aText a:hover{
    text-decoration: none;
    color:black ;
}


.homeItem2b{
    object-fit: contain;
     width: 100%;
    height: 100%; 
}




.imageHomeAbout{
    width: 100%;
    height: 100vh;
    background: #aea191;
}

.imageHomePort{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #a19485;
}


.imageHomeContact{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #cfc5b4;
}

.gridItem{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: rgb(143, 15, 15);
}


@media  only screen and (max-width: 1200px) {

    .homeItem3{
        height: 45vh;
    }

    .homeItem3Img{
        width: 100%;
        position: relative;
        text-align: center;
        
    }
    .homeItem2b{
        object-fit: contain;
         width: 100%;
    }
    .homeItem3Img img{
       margin-left: auto;
       margin-right: auto;
        width: 200px;
       
    }

    .homeItem3Inner{
        padding-top: 20px;
        padding-bottom: 20px;
     
        width: 100%;
      
    }
    .lastImgHome{
        display: none;
    }

  
    .homeItem2aText{
        font-size: 15px;
        width: 405px;
        padding-left: 0;
      
    }
    
   

    .homeFooter div{
       font-size: 15px;
       text-align: center;
    }
    .homeFooter{
        display: none;
        grid-template-columns: 1fr;
        padding-bottom: 25px;
    }
    

  

    
}

@media  only screen and (max-width: 450px) {
.containerRow{
    width: unset;
}
    .homeItem2aText{
        font-size: 13px;
        width: 275px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        
    }
    .homeItem3Img img{
        width: 170px;
    }
}
.aboutContainer{
    
     padding-top: 100px;
     padding-bottom: 100px;
     min-height: 730px;
     height: 90vh;
     display: flex;
     align-content: center;
 }

 @media  only screen and (max-width: 1200px) {
    .aboutContainer{
        max-width: 700px;
        height: 100%;
        
    }
}

@media  only screen and (max-width: 768px) {
    .aboutContainer{
        max-width: 500px;
        
    }
}

@media  only screen and (max-width: 450px) {
  
    .aboutContainer{
        max-width: 400px;
    }
}
.contact{
    
    width: 100%;
    background:#cfc5b4;
    height: 100vh;
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.bild{
    object-fit: cover;
    width: 100%;
    height: 100vh;
}


.slashE{
    font-size: 25px;
    font-weight: 600;
    text-align: left !important;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}
.contactInfo {
    padding-bottom: 100px;
    display: grid;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr 1fr;
    color:white ;
    font-size: 16px;
    letter-spacing: 2px;
    font-family: 'Libre Baskerville', serif;
    
}

.contactInfo2{
    font-style: italic;
    font-size: 15px;
    color:white ;
}

.contactInfo2 a{
    
    text-decoration: none;
}
.contactInfo2:hover{
    color: white;
    text-decoration: none;
}

.contactItem{
    margin: 0 auto;
    display: flex;
    flex-direction: column;   
}


@media  only screen and (max-width: 768px) {
    .contactInfo{
        grid-template-columns: 1fr ;
        grid-gap:20px;
        padding-bottom: 20px;
        width: 270px;
        font-size: 14px;   
    }
    .contact{
        min-height: 800px;
    }


    .contactItem{
        margin:unset;
    }
    .contactInfo2{
        font-size: 12px;
    }
}
.Footer{
    
   
    width: 100%;
    height: 10vh;
    position: relative;
    background: white;
}

.emma{
    font-family:'Montserrat', sans-serif;
    font-size: 16px;
    color: #8b7e71;
    font-weight: 500;
}

.linkSM{
    font-family:'Montserrat', sans-serif;
    font-size: 16px;
    color: #8b7e71;
    font-weight: 500;
    
}

.linkSM:hover{
    color: #584d42;
    text-decoration: none;
}

.FooterContent{
    font-family:"avenir";
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 70%;
    /* padding-left: 200px; */
    padding-right: 40px;
    position: absolute;
    top:50%;
    flex-direction: row;
    left: 50%;
    transform: translate(-50%, -50%);
}

.socialMedia{
    width: 40%;
    display: flex;
    justify-content: space-between;
}

@media  only screen and (max-width: 1200px) {
    .Footer{
       
        height: 15vh;
       
    }
    .FooterContent{
        flex-direction: column;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center;
        display: block;
        width: 100%;
    }
  
    .emma{
        font-size: 13px;
        padding-bottom: 10px;
    }

    .socialMedia{
        
        display: block;
        width: 100%;
    }
    .linkSM{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media  only screen and (max-width: 450px) {

   
}
.layout{
    align-items: flex-end;
    flex-direction:row;
    display: grid;
    width: 100%;
    
    background: white;
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADOSURBVDhPjdK9DgFBGIXhCUFCIVrXIFHqdJR04goUGo07EYXb0FDQazRIRKUkoVAoxP97bEY2+8Oe5El2JzmZ/WYnboxZooQdDnggUvp44YIxGsjhb4q4QmVrhS7yCE0CE7iL1gkDaJQYfKlBswWV5Q6N0UQG32jXNYJKXgt0dKrKE2lUPm+/k8LGeXSik9wjaJczZmgjC1968JZGKCPwcGwKuMGWtFMVf6Pvn8IW59DskVKHLba0EDVJ6P4eEXr17O9wRxdB61sMteCPMW/5REfy1ePlmAAAAABJRU5ErkJggg==), auto; 
}

a{
    cursor: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADOSURBVDhPjdK9DgFBGIXhCUFCIVrXIFHqdJR04goUGo07EYXb0FDQazRIRKUkoVAoxP97bEY2+8Oe5El2JzmZ/WYnboxZooQdDnggUvp44YIxGsjhb4q4QmVrhS7yCE0CE7iL1gkDaJQYfKlBswWV5Q6N0UQG32jXNYJKXgt0dKrKE2lUPm+/k8LGeXSik9wjaJczZmgjC1968JZGKCPwcGwKuMGWtFMVf6Pvn8IW59DskVKHLba0EDVJ6P4eEXr17O9wRxdB61sMteCPMW/5REfy1ePlmAAAAABJRU5ErkJggg==), auto !important; 
}

.engSv{
    padding-right: 5.5rem;
    padding-top: 1rem;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    z-index: 1000;
 
}
.engSv a{
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    overflow: hidden;
    letter-spacing: 3px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */ /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    
}
.engSv a:hover{
    color:rgb(211, 211, 211) !important;
}

.collapsing {
    transition: none !important;
    display: none;
}

.navbartoggled{
    width: 100%;
    height: 100vh;
    background-color: #aea191 !important;
}
.navbarToggler{
    border:none !important;
    
    color: rgba(255, 255, 255, 0.9);
}

.navbarToggler span{
    border:none !important;
    
    color: rgba(255, 255, 255, 0.9) !important;
}

.toggled span{
   background-image:url(/static/media/close.c2593b19.svg) !important;
   color:white !important;
   width: 40px;
}

.notToggled span {
    background-image:url(/static/media/hamburgeropen.4a7e5c5b.svg) !important;
   color:white !important;
   width: 40px;
}

.ml-auto{
    padding-right: 70px;
}

.header{
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-end;
    flex-direction:row;
    display: flex;
    width: 100%;
    
    z-index: 100;
    
}

.navitem {
    
    padding-left: 50px;
    
}



.navitem a{
    
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
    overflow: hidden;
    letter-spacing: 3px;
    
}

.portfolioSubLink{
    font-size: 14px !important;
}

.portfolioSubLinkWrapper{
    width: 100%;
}

.portfolioSubLink:hover {
    color:#cfc5b4 !important;
    text-decoration: none;

}

.navitem a:hover {
    color:white ;

    text-decoration: none;
  }

  .engSv a:hover {
    text-decoration: none;
  }




.navitemBrand{
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 40px;
    float:left;
    padding-left: 125px;
}

.engSvMobile{
    display: none;
}
.engSvMobile a {
    font-weight: 600;
    font-size: 13px;
}

@media  only screen and (max-width: 1200px) {
    .navitem {
        
         padding-left: 25px; 
        
    }
    .navitemBrand{
        font-size: 20px;
        text-align: center;
        padding-left: 0;
    }
    .ml-auto{
        text-align: center;
        padding-right: 0;
        padding-top: 50px;
    }
}

@media  only screen and (max-width: 768px) {
.dropdownNavContent{
    text-align: center !important;
    width: 100%;
}
    .navitem {
        
         padding-left: 0px; 
        
    }
    .engSv{
               display: none;
    }

    .engSvMobile{
        display: block;
        padding-top:40px;
    }
  
}
  
.about{
    width: 100%;
    background:#aea191;
    height: 100vh;
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.oGridAbout{
    width: 500px !important;
}


.aboutfrontGrid{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    width: 450px;
    height: 500px;
    color:white ;
    font-size: 18px;
    font-family: 'Baskervville', serif;
}

.omMigHeader{
     padding-bottom: 40px;
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.hrAbout{
    border-top: 1px solid #fff9;
    pointer-events:none;
    width: 100%;
    
}

.omMigText{
    font-family: 'Baskervville', serif;
    font-size: 18px;
}

.hrEmma{
    border-top: 1.5px solid rgba(15, 15, 15, 0.6);
    pointer-events:none;
    width: 30%;
    margin-left:0;
    margin-top: 0;
    margin-bottom: 5;
}
.aboutGrid{
    display: grid;
    width: 100%;
    
}

.aboutItem2{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    width: 100%;
    
    height: 100vh;
    
}

.aboutItem2a{
    
    width: 100%;
    height: 100%;
    display: flex;
   
    
}
.tenquick{

 padding-left: 0;
 color:rgba(20, 20, 20, 1);
 font-size: 16px;
 font-family: 'Baskervville', serif;

}

.quickOne{
    padding-bottom: 12px;
}

.aboutImage{
    object-fit: contain;
    width: 100%;
    
    
}
.aboutItem2aText{
    color:rgb(61, 61, 61) ;
    font-family: 'Montserrat', serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    padding-bottom: 40px;
    width:620px; 
    align-self: center;
}

.aboutItem2aHead{
    color:rgb(0, 0, 0) ;
    font-family: 'Montserrat', serif;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;
  
   
  
}

.aboutItem3{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    width: 100%;
    background-color: #DCD4CD;
    height: 100vh;
}
.aboutItem2b{
    position: relative;
}
.aboutItem2 img{
    width:600px; 
    height:700px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.aboutItem2 img{
    width:600px; 
    height:700px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media  only screen and (max-width: 1200px) {
    
   
    .aboutItem2a{
        position: unset;
      
    }
    .aboutItem2aText{
        position: relative;
        width: unset;
        
    }
}
@media  only screen and (max-width: 768px) {
    .oGridAbout{
        width: 300px !important;
    }
    .about{
        min-height: 720px;
    }
    .aboutItem2{
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        
        height: 100%;
    }
   
    .aboutItem2aText{
        font-size: 13px;
        width: 100%;
        padding-left: 0;
        
    }
    .aboutItem2b{
        width: 100%;
    }
    .aboutItem2b img{
        width: 80% !important;
        height: 400px !important;
        padding-bottom: 40px;
    }
}

@media  only screen and (max-width: 450px) {
    .omMigText{
        font-size: 15px;
    }
  
}


.outerPortfolio{
    width: 100%;
    background:#a19485;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.portfolio{
    width: 100%;
    background:#a19485;
    height: 100vh;
    min-height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.oGrid{
    padding-top: 250px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left:auto;
    margin-right:auto;
    width: 425px;
    min-height: 400px;
    color:white ;
    font-size: 18px;
    letter-spacing: 2px;
    
    font-family: 'Baskervville', serif;
}

.PorfolioHeader{
    padding-bottom: 40px;
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.hrAbout{
    border-top: 1px solid #fff9;
    pointer-events:none;
    width: 100%;
    
}

.hrAboutUpper{
    border-top: 1px solid #fff9;
    pointer-events:none;
    width: 100%;
}

.hrPortfolio{
    border-top: 1px solid black;
   
    width: 250px;
    margin-left:0;
    
}
.portfolioGrid{
    display: grid;
    width: 100%;
    min-height: 100vh;
}

.portfolioItem2{
    
    width: 100%;
    height: 100vh;
    display: flex;
    background:white;
    min-height: 802px;
    
}

.modulo{
   
    
    background-color: #DCD4CD;
   
    
}

.portfolioItemGrid{
    display: grid;
    width: 85%;
    grid-template: 'pi2T image ' 90%
                        'cPic image ' 10%
                        / 35% 65% ;
    
                             
    margin-left: auto;
    margin-right: auto;    
    margin-top: auto;     
    margin-bottom: auto;    
    /* height: ; */
    max-height: 614px;

}



.portfolioItem2a{
    display: flex;
    grid-area: pi2T;
    width: 500px;
    
}

.portfolioItem2text{
    max-width: 400px;

    color:grey ;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 11.5px;
    line-height: 1.8;
    /* padding-left: 30px; */
    
    align-self: flex-start;
    
   
}

.portfolioItem2head{
    color:rgb(0, 0, 0) ;
    font-family: 'Libre Baskerville', serif;
    font-size: 25px;
    font-style: italic;
}

.portfolioItem2head2{
    color:rgb(0, 0, 0) ;
    font-family: 'Libre Baskerville', serif;
    font-size: 15px;
}

.portfolioItemNo{
    color:rgb(0, 0, 0) ;
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 600;
}


.portfolioItem3b{
    position: relative;
}


.portfolioItem2b{
    
    padding-left: 20px;
    position: relative;
    grid-area: image;
    width: 100%;
    height: 530px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    
}

.portfolioItemGrid img{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    object-fit: contain;

   
}


.portfolioChangePicContainer{
    position: relative;
}
.portfolioChangePic{
    
    height: 45px;
    padding-left: 30px;
    grid-area: cPic;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom:0;
    width: 100%;
    
}
.portfolioNo1State{
    font-family: 'Montserrat';
    display: flex;
    
    position: relative;
    width: 90px;
}
.No1State{
    font-weight: 600;
    font-size: 40px;
    
}

.noOfPic{
    position: absolute;
    bottom: -5px;
    right: 0;
    font-size: 20px;
    font-weight: 600;
}

.buttons{
    position: absolute;
    bottom: 0;
    right: 15px;
    display: flex;
    
}

.buttonsize{
    width: 45px;
    height: 45px;
    position: relative;
}

.buttonPort1{
    position: absolute;
    bottom:10px;
    left:50%;
    display: inline-block;
    opacity: .9;
    border: solid rgb(0, 0, 0);
    border-width: 0 2px 2px 0;
    transform: rotate(
        135deg);
    height: 20px;
    width: 20px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    
}
.buttonPort2{
    position: absolute;
    bottom:10px;
    left:0;
    display: inline-block;
    opacity: .9;
    border: solid rgb(0, 0, 0);
    border-width: 0 2px 2px 0;
    transform: rotate(
        -45deg);
    height: 20px;
    width: 20px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    
}

.portfolioYear{
    margin-top: 40px;
}

@media  only screen and (max-width: 1200px) {
    .portfolioChangePicContainer{
        width: 400px;
        padding-top: 10px;
        
        width: 100%;
        
        
    }
    .portfolioChangePic{
        
        
        margin-left: auto;
        position: relative;
        margin-right: auto;
      
        
    }
    .portfolioItem2{
        min-height: 802px;
        max-height: 802px;
    }
    .portfolioItem2a{
        justify-content: center;
        padding-top: 0px;
        width: 100%;
    }
    .portfolioItem2b{
        padding-left: 0px;
        height: 350px;
        width: 100%;
       
        
    }

    .PorfolioHeader{
        font-size: 20px;
        padding-bottom: 20px;
    }
    .hrAboutUpper{
        
        padding-bottom: 0;
    }
    .portfolioItemGrid img{
        max-height: 400px;
        width: 100%;
        
    }
    .portfolioItemGrid{
        grid-template-columns: unset;
        grid-template: unset;
        grid-template-areas: 'pi2T'
                             'pi2T'
                             'image'
                            'image'
                            'cPic';
        height: 100%;
        margin-top: 30px;
        max-height: 750px;
        
    }
    .portfolioItem2text{
        font-size: 10px;
    }
    .portfolioItem2head{
        font-size: 18px;
    }
    .portfolioItem2head2{
        font-size: 15px;
    }

    .buttonsize{
        width: 45px;
        height: 45px;
    }
 

}

@media  only screen and (max-width: 450px) {
    .No1State{
        font-size: 35px;
    }
    .portfolioNo1State{
        width: 85px;
    }
    .noOfPic{
        bottom: 0;
    }
    .oGrid{
        padding-top: 150px;
        font-size: 15px;
        width: 270px;
        display: block;
        padding-top: 200px;
        
    }

    .portfolioItemGrid img{
        max-height: 270px;
    }
    .portfolioItemGrid{
        height: 90%;
        margin-top: 20px;
        max-height: 614px;
    }
      .portfolioChangePicContainer{
        width: 100%;
        padding-top: 10px;
        
    }
    .portfolioChangePic{
       
        width: 100%;
        padding-left: 0px;
     
        
    }
    .buttons{
        right: 0px;
    }
    .portfolioItem2{
        min-height: 700px;
    }
    .portfolioItem2a{
        justify-content: flex-start;
        width: 100%;
    }
    .portfolioItem2b{
        height: 270px;
        width: 100%;
        
    }
}
.App {
  text-align: center;
  padding-top: 156px;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background{
  
  
}
.gridContainer{
  height: 160vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background: #cfc5b4;
}
.theGrid{
  display: grid;
  grid-gap:60px;
  gap:60px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr
}
.theGrid div{
  background-size: 100% 100%;

  align-items: center;
  justify-content: center;
  display: flex;
   width: 40vw;
  height: 45vh;
  color: rgba(255, 255, 255, 0.89);
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 600;
}

.theGrid a{
  color:white ;
}
.theGrid a:hover{
  text-decoration: none;
  color:#cfc5b4 ;
}
.grid-item1{
 background-image: url("/static/media/VILLA T - DATOR.8b7e77d6.jpg");
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}
.grid-item2{
 background-image: url("/static/media/APARTMENT B - DATOR.c62aebcf.jpg");
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;

}
.grid-item3{
 background-image: url("/static/media/VILLA Å - DATOR.cec5a00f.jpg");
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;

}
.grid-item4{
 background-image: url(/static/media/OFFICES-DATOR.0f7984a2.jpg);
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}
.grid-item5{
  grid-column-start:1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}
.grid-item6{
  grid-column-start:2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}
.villaA{
  height:100vh;
  width:100vw;
}



.interiorObject{
  /* height:100vh;
  width:100vw;
  background:#a19485; */
}

.interiorStart{
  min-height: 700px;
  height:100vh;
  width:100vw;
  background:#a19485;
}

.InteriorItem{
  position: relative;
  height:100vh;
  width:80%;
  padding-top:64px;
margin-left:auto;
margin-right:auto;
}

.interiorItemImg img{
  max-width:60vw;
  object-fit:contain;
}

/* APartmentB */
.interiorItem1{
margin-bottom: 80px;
  
}


.InteriorItemHeader{
  letter-spacing: 4.5px;
  color:rgb(0, 0, 0) ;
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: 600;
}

@media  only screen and (max-width: 768px) {
.grid-item1{
 background-image: url("/static/media/VILLA T - MOBIL.57237852.jpg");

}
.grid-item2{
 background-image: url("/static/media/APT B - MOBIL.d47d0bb9.jpg");

}
.grid-item3{
 background-image: url("/static/media/VILLA Å - MOBIL.85af86b8.jpg");

}
.grid-item4{
 background-image: url("/static/media/OFFICE S - MOBIL.16fe372d.jpg");

}
  .gridContainer{
height: 100%;
  }
  .theGrid{
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
  }

  .theGrid div{
    width: 70vw;
  }
  
}
