@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap');

.outerPortfolio{
    width: 100%;
    background:#a19485;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.portfolio{
    width: 100%;
    background:#a19485;
    height: 100vh;
    min-height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.oGrid{
    padding-top: 250px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-left:auto;
    margin-right:auto;
    width: 425px;
    min-height: 400px;
    color:white ;
    font-size: 18px;
    letter-spacing: 2px;
    
    font-family: 'Baskervville', serif;
}

.PorfolioHeader{
    padding-bottom: 40px;
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.hrAbout{
    border-top: 1px solid #fff9;
    pointer-events:none;
    width: 100%;
    
}

.hrAboutUpper{
    border-top: 1px solid #fff9;
    pointer-events:none;
    width: 100%;
}

.hrPortfolio{
    border-top: 1px solid black;
   
    width: 250px;
    margin-left:0;
    
}
.portfolioGrid{
    display: grid;
    width: 100%;
    min-height: 100vh;
}

.portfolioItem2{
    
    width: 100%;
    height: 100vh;
    display: flex;
    background:white;
    min-height: 802px;
    
}

.modulo{
   
    
    background-color: #DCD4CD;
   
    
}

.portfolioItemGrid{
    display: grid;
    width: 85%;
    grid-template: 'pi2T image ' 90%
                        'cPic image ' 10%
                        / 35% 65% ;
    
                             
    margin-left: auto;
    margin-right: auto;    
    margin-top: auto;     
    margin-bottom: auto;    
    /* height: ; */
    max-height: 614px;

}



.portfolioItem2a{
    display: flex;
    grid-area: pi2T;
    width: 500px;
    
}

.portfolioItem2text{
    max-width: 400px;

    color:grey ;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 11.5px;
    line-height: 1.8;
    /* padding-left: 30px; */
    
    align-self: flex-start;
    
   
}

.portfolioItem2head{
    color:rgb(0, 0, 0) ;
    font-family: 'Libre Baskerville', serif;
    font-size: 25px;
    font-style: italic;
}

.portfolioItem2head2{
    color:rgb(0, 0, 0) ;
    font-family: 'Libre Baskerville', serif;
    font-size: 15px;
}

.portfolioItemNo{
    color:rgb(0, 0, 0) ;
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 600;
}


.portfolioItem3b{
    position: relative;
}


.portfolioItem2b{
    
    padding-left: 20px;
    position: relative;
    grid-area: image;
    width: 100%;
    height: 530px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    
}

.portfolioItemGrid img{
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    object-fit: contain;

   
}


.portfolioChangePicContainer{
    position: relative;
}
.portfolioChangePic{
    
    height: 45px;
    padding-left: 30px;
    grid-area: cPic;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom:0;
    width: 100%;
    
}
.portfolioNo1State{
    font-family: 'Montserrat';
    display: flex;
    
    position: relative;
    width: 90px;
}
.No1State{
    font-weight: 600;
    font-size: 40px;
    
}

.noOfPic{
    position: absolute;
    bottom: -5px;
    right: 0;
    font-size: 20px;
    font-weight: 600;
}

.buttons{
    position: absolute;
    bottom: 0;
    right: 15px;
    display: flex;
    
}

.buttonsize{
    width: 45px;
    height: 45px;
    position: relative;
}

.buttonPort1{
    position: absolute;
    bottom:10px;
    left:50%;
    display: inline-block;
    opacity: .9;
    border: solid rgb(0, 0, 0);
    border-width: 0 2px 2px 0;
    transform: rotate(
        135deg);
    height: 20px;
    width: 20px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    
}
.buttonPort2{
    position: absolute;
    bottom:10px;
    left:0;
    display: inline-block;
    opacity: .9;
    border: solid rgb(0, 0, 0);
    border-width: 0 2px 2px 0;
    transform: rotate(
        -45deg);
    height: 20px;
    width: 20px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    
}

.portfolioYear{
    margin-top: 40px;
}

@media  only screen and (max-width: 1200px) {
    .portfolioChangePicContainer{
        width: 400px;
        padding-top: 10px;
        
        width: 100%;
        
        
    }
    .portfolioChangePic{
        
        
        margin-left: auto;
        position: relative;
        margin-right: auto;
      
        
    }
    .portfolioItem2{
        min-height: 802px;
        max-height: 802px;
    }
    .portfolioItem2a{
        justify-content: center;
        padding-top: 0px;
        width: 100%;
    }
    .portfolioItem2b{
        padding-left: 0px;
        height: 350px;
        width: 100%;
       
        
    }

    .PorfolioHeader{
        font-size: 20px;
        padding-bottom: 20px;
    }
    .hrAboutUpper{
        
        padding-bottom: 0;
    }
    .portfolioItemGrid img{
        max-height: 400px;
        width: 100%;
        
    }
    .portfolioItemGrid{
        grid-template-columns: unset;
        grid-template: unset;
        grid-template-areas: 'pi2T'
                             'pi2T'
                             'image'
                            'image'
                            'cPic';
        height: 100%;
        margin-top: 30px;
        max-height: 750px;
        
    }
    .portfolioItem2text{
        font-size: 10px;
    }
    .portfolioItem2head{
        font-size: 18px;
    }
    .portfolioItem2head2{
        font-size: 15px;
    }

    .buttonsize{
        width: 45px;
        height: 45px;
    }
 

}

@media  only screen and (max-width: 450px) {
    .No1State{
        font-size: 35px;
    }
    .portfolioNo1State{
        width: 85px;
    }
    .noOfPic{
        bottom: 0;
    }
    .oGrid{
        padding-top: 150px;
        font-size: 15px;
        width: 270px;
        display: block;
        padding-top: 200px;
        
    }

    .portfolioItemGrid img{
        max-height: 270px;
    }
    .portfolioItemGrid{
        height: 90%;
        margin-top: 20px;
        max-height: 614px;
    }
      .portfolioChangePicContainer{
        width: 100%;
        padding-top: 10px;
        
    }
    .portfolioChangePic{
       
        width: 100%;
        padding-left: 0px;
     
        
    }
    .buttons{
        right: 0px;
    }
    .portfolioItem2{
        min-height: 700px;
    }
    .portfolioItem2a{
        justify-content: flex-start;
        width: 100%;
    }
    .portfolioItem2b{
        height: 270px;
        width: 100%;
        
    }
}