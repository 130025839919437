@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.layout{
    align-items: flex-end;
    flex-direction:row;
    display: grid;
    width: 100%;
    
    background: white;
    cursor: url('images/cursor2.png'), auto ; 
}

a{
    cursor: url('images/cursor2.png'), auto !important; 
}

.engSv{
    padding-right: 5.5rem;
    padding-top: 1rem;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    z-index: 1000;
 
}
.engSv a{
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    overflow: hidden;
    letter-spacing: 3px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    
}
.engSv a:hover{
    color:rgb(211, 211, 211) !important;
}

.collapsing {
    -webkit-transition: none;
    transition: none !important;
    display: none;
}

.navbartoggled{
    width: 100%;
    height: 100vh;
    background-color: #aea191 !important;
}
.navbarToggler{
    border:none !important;
    
    color: rgba(255, 255, 255, 0.9);
}

.navbarToggler span{
    border:none !important;
    
    color: rgba(255, 255, 255, 0.9) !important;
}

.toggled span{
   background-image:url(./icons/close.svg) !important;
   color:white !important;
   width: 40px;
}

.notToggled span {
    background-image:url(./icons/hamburgeropen.svg) !important;
   color:white !important;
   width: 40px;
}

.ml-auto{
    padding-right: 70px;
}

.header{
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-end;
    flex-direction:row;
    display: flex;
    width: 100%;
    
    z-index: 100;
    
}

.navitem {
    
    padding-left: 50px;
    
}



.navitem a{
    
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
    overflow: hidden;
    letter-spacing: 3px;
    
}

.portfolioSubLink{
    font-size: 14px !important;
}

.portfolioSubLinkWrapper{
    width: 100%;
}

.portfolioSubLink:hover {
    color:#cfc5b4 !important;
    text-decoration: none;

}

.navitem a:hover {
    color:white ;

    text-decoration: none;
  }

  .engSv a:hover {
    text-decoration: none;
  }




.navitemBrand{
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 40px;
    float:left;
    padding-left: 125px;
}

.engSvMobile{
    display: none;
}
.engSvMobile a {
    font-weight: 600;
    font-size: 13px;
}

@media  only screen and (max-width: 1200px) {
    .navitem {
        
         padding-left: 25px; 
        
    }
    .navitemBrand{
        font-size: 20px;
        text-align: center;
        padding-left: 0;
    }
    .ml-auto{
        text-align: center;
        padding-right: 0;
        padding-top: 50px;
    }
}

@media  only screen and (max-width: 768px) {
.dropdownNavContent{
    text-align: center !important;
    width: 100%;
}
    .navitem {
        
         padding-left: 0px; 
        
    }
    .engSv{
               display: none;
    }

    .engSvMobile{
        display: block;
        padding-top:40px;
    }
  
}
  