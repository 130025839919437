@import url('https://fonts.googleapis.com/css2?family=Baskervville&display=swap');
.about{
    width: 100%;
    background:#aea191;
    height: 100vh;
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.oGridAbout{
    width: 500px !important;
}


.aboutfrontGrid{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    width: 450px;
    height: 500px;
    color:white ;
    font-size: 18px;
    font-family: 'Baskervville', serif;
}

.omMigHeader{
     padding-bottom: 40px;
    color:white ;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.hrAbout{
    border-top: 1px solid #fff9;
    pointer-events:none;
    width: 100%;
    
}

.omMigText{
    font-family: 'Baskervville', serif;
    font-size: 18px;
}

.hrEmma{
    border-top: 1.5px solid rgba(15, 15, 15, 0.6);
    pointer-events:none;
    width: 30%;
    margin-left:0;
    margin-top: 0;
    margin-bottom: 5;
}
.aboutGrid{
    display: grid;
    width: 100%;
    
}

.aboutItem2{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    width: 100%;
    
    height: 100vh;
    
}

.aboutItem2a{
    
    width: 100%;
    height: 100%;
    display: flex;
   
    
}
.tenquick{

 padding-left: 0;
 color:rgba(20, 20, 20, 1);
 font-size: 16px;
 font-family: 'Baskervville', serif;

}

.quickOne{
    padding-bottom: 12px;
}

.aboutImage{
    object-fit: contain;
    width: 100%;
    
    
}
.aboutItem2aText{
    color:rgb(61, 61, 61) ;
    font-family: 'Montserrat', serif;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.8;
    padding-bottom: 40px;
    width:620px; 
    align-self: center;
}

.aboutItem2aHead{
    color:rgb(0, 0, 0) ;
    font-family: 'Montserrat', serif;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;
  
   
  
}

.aboutItem3{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    width: 100%;
    background-color: #DCD4CD;
    height: 100vh;
}
.aboutItem2b{
    position: relative;
}
.aboutItem2 img{
    width:600px; 
    height:700px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.aboutItem2 img{
    width:600px; 
    height:700px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media  only screen and (max-width: 1200px) {
    
   
    .aboutItem2a{
        position: unset;
      
    }
    .aboutItem2aText{
        position: relative;
        width: unset;
        
    }
}
@media  only screen and (max-width: 768px) {
    .oGridAbout{
        width: 300px !important;
    }
    .about{
        min-height: 720px;
    }
    .aboutItem2{
        display: grid;
        grid-template-columns: 1fr ;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        
        height: 100%;
    }
   
    .aboutItem2aText{
        font-size: 13px;
        width: 100%;
        padding-left: 0;
        
    }
    .aboutItem2b{
        width: 100%;
    }
    .aboutItem2b img{
        width: 80% !important;
        height: 400px !important;
        padding-bottom: 40px;
    }
}

@media  only screen and (max-width: 450px) {
    .omMigText{
        font-size: 15px;
    }
  
}

