.gridContainer{
  height: 160vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  background: #cfc5b4;
}
.theGrid{
  display: grid;
  gap:60px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr
}
.theGrid div{
  background-size: 100% 100%;

  align-items: center;
  justify-content: center;
  display: flex;
   width: 40vw;
  height: 45vh;
  color: rgba(255, 255, 255, 0.89);
    font-family: 'Montserrat';
    font-size: 34px;
    font-weight: 600;
}

.theGrid a{
  color:white ;
}
.theGrid a:hover{
  text-decoration: none;
  color:#cfc5b4 ;
}
.grid-item1{
 background-image: url("ProjectBackgrounds/VILLA\ T\ -\ DATOR.jpg");
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}
.grid-item2{
 background-image: url("ProjectBackgrounds/APARTMENT\ B\ -\ DATOR.jpg");
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;

}
.grid-item3{
 background-image: url("ProjectBackgrounds/VILLA\ Å\ -\ DATOR.jpg");
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;

}
.grid-item4{
 background-image: url("ProjectBackgrounds/OFFICES-DATOR.jpg");
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
}
.grid-item5{
  grid-column-start:1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
}
.grid-item6{
  grid-column-start:2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
}
.villaA{
  height:100vh;
  width:100vw;
}



.interiorObject{
  /* height:100vh;
  width:100vw;
  background:#a19485; */
}

.interiorStart{
  min-height: 700px;
  height:100vh;
  width:100vw;
  background:#a19485;
}

.InteriorItem{
  position: relative;
  height:100vh;
  width:80%;
  padding-top:64px;
margin-left:auto;
margin-right:auto;
}

.interiorItemImg img{
  max-width:60vw;
  object-fit:contain;
}

/* APartmentB */
.interiorItem1{
margin-bottom: 80px;
  
}


.InteriorItemHeader{
  letter-spacing: 4.5px;
  color:rgb(0, 0, 0) ;
  font-family: 'Montserrat';
  font-size: 30px;
  font-weight: 600;
}

@media  only screen and (max-width: 768px) {
.grid-item1{
 background-image: url("ProjectBackgrounds/mobil/VILLA\ T\ -\ MOBIL.jpg");

}
.grid-item2{
 background-image: url("ProjectBackgrounds/mobil/APT\ B\ -\ MOBIL.jpg");

}
.grid-item3{
 background-image: url("ProjectBackgrounds/mobil/VILLA\ Å\ -\ MOBIL.jpg");

}
.grid-item4{
 background-image: url("ProjectBackgrounds/mobil/OFFICE\ S\ -\ MOBIL.jpg");

}
  .gridContainer{
height: 100%;
  }
  .theGrid{
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: column;
  }

  .theGrid div{
    width: 70vw;
  }
  
}