.contact{
    
    width: 100%;
    background:#cfc5b4;
    height: 100vh;
    min-height: 800px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
}

.bild{
    object-fit: cover;
    width: 100%;
    height: 100vh;
}


.slashE{
    font-size: 25px;
    font-weight: 600;
    text-align: left !important;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}
.contactInfo {
    padding-bottom: 100px;
    display: grid;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: 1fr 1fr 1fr;
    color:white ;
    font-size: 16px;
    letter-spacing: 2px;
    font-family: 'Libre Baskerville', serif;
    
}

.contactInfo2{
    font-style: italic;
    font-size: 15px;
    color:white ;
}

.contactInfo2 a{
    
    text-decoration: none;
}
.contactInfo2:hover{
    color: white;
    text-decoration: none;
}

.contactItem{
    margin: 0 auto;
    display: flex;
    flex-direction: column;   
}


@media  only screen and (max-width: 768px) {
    .contactInfo{
        grid-template-columns: 1fr ;
        grid-gap:20px;
        padding-bottom: 20px;
        width: 270px;
        font-size: 14px;   
    }
    .contact{
        min-height: 800px;
    }


    .contactItem{
        margin:unset;
    }
    .contactInfo2{
        font-size: 12px;
    }
}