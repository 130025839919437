@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
.Footer{
    
   
    width: 100%;
    height: 10vh;
    position: relative;
    background: white;
}

.emma{
    font-family:'Montserrat', sans-serif;
    font-size: 16px;
    color: #8b7e71;
    font-weight: 500;
}

.linkSM{
    font-family:'Montserrat', sans-serif;
    font-size: 16px;
    color: #8b7e71;
    font-weight: 500;
    
}

.linkSM:hover{
    color: #584d42;
    text-decoration: none;
}

.FooterContent{
    font-family:"avenir";
    margin: auto;
    display: flex;
    justify-content: space-between;
    width: 70%;
    /* padding-left: 200px; */
    padding-right: 40px;
    position: absolute;
    top:50%;
    flex-direction: row;
    left: 50%;
    transform: translate(-50%, -50%);
}

.socialMedia{
    width: 40%;
    display: flex;
    justify-content: space-between;
}

@media  only screen and (max-width: 1200px) {
    .Footer{
       
        height: 15vh;
       
    }
    .FooterContent{
        flex-direction: column;
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-align: center;
        display: block;
        width: 100%;
    }
  
    .emma{
        font-size: 13px;
        padding-bottom: 10px;
    }

    .socialMedia{
        
        display: block;
        width: 100%;
    }
    .linkSM{
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
}
@media  only screen and (max-width: 450px) {

   
}