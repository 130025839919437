@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital@0;1&family=Montserrat&display=swap');

.homeGrid{
    display: grid;
    width: 100%;
    min-width: 320px;
}

.home{
    
    width: 100%;
    background: url(../images/1.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.homeFooter{
    position: absolute;
    bottom:0;
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-around;
    align-content: flex-start;
    color:rgba(255, 255, 255, 0.925) !important;
    font-family: 'Libre Baskerville', serif;
    letter-spacing: 3px;
    font-weight: 400;
    font-size: 16px;
}



.homeFooterSub{
    font-style: italic;
    color: #ffffffa9;
}


.homeItem2{
    display: grid;
    grid-template-columns: 1fr 1fr ;
    width: 100%;
    min-height: 800px;
    height: 100vh;
    
}

.homeItem2a{
    
    min-height: 275px;
    /* height: 60vh; */
    
}

.containerRow{
    width: 100%;
}
.homeItem2aText{
    color:grey ;
    font-family: 'Libre Baskerville', serif;
    letter-spacing: 1.6px;

    font-weight: 400;
    font-size: 16.5px;
    line-height: 2;
    padding-left: 30px;
    width:440px; 
    height:300px;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Hello{
    font-size: 50px;
    font-weight: 400;
    line-height: 0.8;
}
.homeItem2aText a{
    color:grey ;
    font-style: italic;
    font-family: 'Libre Baskerville', serif;
}
.homeItem2aText a:hover{
    text-decoration: none;
    color:black ;
}


.homeItem2b{
    object-fit: contain;
     width: 100%;
    height: 100%; 
}




.imageHomeAbout{
    width: 100%;
    height: 100vh;
    background: #aea191;
}

.imageHomePort{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #a19485;
}


.imageHomeContact{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #cfc5b4;
}

.gridItem{
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    color: rgb(143, 15, 15);
}


@media  only screen and (max-width: 1200px) {

    .homeItem3{
        height: 45vh;
    }

    .homeItem3Img{
        width: 100%;
        position: relative;
        text-align: center;
        
    }
    .homeItem2b{
        object-fit: contain;
         width: 100%;
    }
    .homeItem3Img img{
       margin-left: auto;
       margin-right: auto;
        width: 200px;
       
    }

    .homeItem3Inner{
        padding-top: 20px;
        padding-bottom: 20px;
     
        width: 100%;
      
    }
    .lastImgHome{
        display: none;
    }

  
    .homeItem2aText{
        font-size: 15px;
        width: 405px;
        padding-left: 0;
      
    }
    
   

    .homeFooter div{
       font-size: 15px;
       text-align: center;
    }
    .homeFooter{
        display: none;
        grid-template-columns: 1fr;
        padding-bottom: 25px;
    }
    

  

    
}

@media  only screen and (max-width: 450px) {
.containerRow{
    width: unset;
}
    .homeItem2aText{
        font-size: 13px;
        width: 275px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
        
    }
    .homeItem3Img img{
        width: 170px;
    }
}